import React from 'react';
import dynamic from 'next/dynamic';
import MainBannerSkeleton from '../FloorSkeleton/MainBannerSkeleton';
import MainBannerSkeletonV2 from '../FloorSkeleton/MainbannerSkeletonV2';
import MiniBannerSkeleton from '../FloorSkeleton/MiniBannerSkeleton';
import FourBannerSkeleton from '../FloorSkeleton/FourBannerSkeleton';
import ProductCarouselSkeleton from '../FloorSkeleton/ProductCarouselSkeleton';
import HeroBannerSkeleton from '../FloorSkeleton/HeroBannerSkeleton';
import HeroBannerSkeletonV2 from '../FloorSkeleton/HeroBannerSkeletonV2';
import ProductCollageSkeleton from '../FloorSkeleton/ProductCollageSkeleton';
import PromiseSekelton from '../FloorSkeleton/PromiseSkeleton';
import CategoryListingSkeleton from '../FloorSkeleton/CategoryListingSkeleton';
import MultipleBannerSkeleton from '../FloorSkeleton/MultipleBannerSkeleton';
import BlogBannerSkeleton from '../FloorSkeleton/BlogBannerSkeleton';
import ProductScrollSkeleton from '../FloorSkeleton/ProductScrollSkeleton';
import AppLinkSkeleton from '../FloorSkeleton/AppLinkSkeleton';
import Banner from '../MainBanner/Banner';
import PromiseSekeltonV2 from '../FloorSkeleton/PromiseSkeletonV2';
import CategoryCarouselSkeleton from '../FloorSkeleton/CategoryCarouselSkeleton';
import ShopByCategorySkeleton from '../FloorSkeleton/ShopByCategorySkeleton';
import ShopByCategoryScrollSkeleton from '../FloorSkeleton/ShopByCategoryScrollSkeleton';
import DualBannerSkeleton from '../FloorSkeleton/DualBannerSkeleton';
import HeadingMainTitleSkeleton from '../FloorSkeleton/HeadingMainTitleSkeleton';
import CategoryDescriptionSkeleton from '../FloorSkeleton/CategoryDescriptionSkeleton';
import BannerVersion1 from '../MainBanner/BannerV1';

const MainBanner = dynamic(() => import('../MainBanner/MainBanner'), {
  loading: () => <MainBannerSkeleton />,
  ssr: false,
});

const MainBannerV2 = dynamic(() => import('../MainBanner/MainbannerV2'), {
  loading: () => <MainBannerSkeletonV2 />,
  ssr: false,
});

const getCallMainbannerV2 = (data) => {
  const mainBanners = data.filter((banner) => banner.floorType !== 'top_three');
  const MainBannerVersion2 = dynamic(() => import('../MainBanner/MainbannerV2'), {
    loading: () => <Banner banner={mainBanners[0]} handleClick={() => {}} i={-1} maindata={{}} />,
    ssr: false,
  });
  return MainBannerVersion2;
};

const getCallMainbannerV1 = (data) => {
  const mainBanners = data.filter((banner) => banner.floorType !== 'top_three');
  const MainBannerV1 = dynamic(() => import('../MainBanner/MainBanner'), {
    loading: () => <BannerVersion1 banner={mainBanners[0]} handleClick={() => {}} i={-1} maindata={{}} />,
    ssr: false,
  });
  return MainBannerV1;
};

const MiniBanner = dynamic(() => import('../MultipleBanner/MiniBanner'), {
  loading: () => <MiniBannerSkeleton />,
  ssr: false,
});

// New floor MiniBanner or FourBanner
const FourBanner = dynamic(() => import('../FourBanner/FourBanner'), {
  loading: () => <FourBannerSkeleton />,
  ssr: false,
});

const MultipleBigBanner = dynamic(() => import('../MultipleBanner/MultipleBigBanner'), {
  loading: () => <MultipleBannerSkeleton />,
  ssr: false,
});
// New Dual Banner floor
const DualBannerCard = dynamic(() => import('../DualBannerCard/DualBannerCard'), {
  loading: () => <DualBannerSkeleton />,
  ssr: false,
});

// New blog banner or multiple banner
const BlogBanner = dynamic(() => import('../BlogBanner/BlogBanner'), {
  loading: () => <BlogBannerSkeleton />,
  ssr: false,
});

const HeroBanner = dynamic(() => import('../ProductBanner/HeroBanner'), {
  loading: () => <HeroBannerSkeleton />,
  ssr: false,
});
// New hero Banner
const HeroBannerV2 = dynamic(() => import('../HeroBannerV2/HeroBannerV2'), {
  loading: () => <HeroBannerSkeletonV2 />,
  ssr: false,
});

const PromiseFloor = dynamic(() => import('../PromiseTabs/promiseFloor'), {
  loading: () => <PromiseSekelton />,
  ssr: false,
});

// New HeadingMainTitle floor
const HeadingMainTitle = dynamic(() => import('../HeadingMainTitle/HeadingMainTitle'), {
  loading: () => <HeadingMainTitleSkeleton />,
  ssr: false,
});

// New Promise floor
const PromiseFloorV2 = dynamic(() => import('../PromiseTabs/PromiseFloorV2'), {
  loading: () => <PromiseSekeltonV2 />,
  ssr: false,
});

const ProductCollage = dynamic(() => import('../Productcollage/Productcollage'), {
  loading: () => <ProductCollageSkeleton />,
  ssr: false,
});
const ProductCarouselTitle = dynamic(() => import('../ProductCarouselTitle/ProductCarouselTitle'), {
  loading: () => <ProductCarouselSkeleton />,
  ssr: false,
});

const DyProductScroll = dynamic(() => import('../DynamicYieldFloors/DyProductScroll'), {
  loading: () => <ProductCarouselSkeleton />,
  ssr: false,
});

const ProductCarouselTitlePerso = dynamic(() => import('../ProductCarouselTitle/ProductCarouselTitlePerso'), {
  loading: () => <ProductScrollSkeleton />,
  ssr: false,
});
const CategoryListing = dynamic(() => import('../CategoryListing/CategoryListing'), {
  loading: () => <CategoryListingSkeleton />,
  ssr: false,
});
const Persoreco = dynamic(() => import('../PersoReco/Persoreco'), {
  loading: () => <ProductScrollSkeleton />,
  ssr: false,
});

// New Category links floor
const CategoryCarousel = dynamic(() => import('../CategoryCarousel/CategoryCarousel'), {
  loading: () => <CategoryCarouselSkeleton />,
  ssr: false,
});
const ProductScroll = dynamic(() => import('../ProductScroll/ProductScroll'), {
  loading: () => <ProductScrollSkeleton />,
  ssr: false,
});

const AppLink = dynamic(() => import('../AppLink/AppLink'), {
  loading: () => <AppLinkSkeleton />,
  ssr: false,
});

const ShopByCategory = dynamic(() => import('../ShopByCategory/ShopByCategory'), {
  loading: () => <ShopByCategorySkeleton />,
  ssr: false,
});

const ProductRecall = dynamic(() => import('../ProductRecall/ProductRecall'), {
  loading: () => <ProductScrollSkeleton />,
  ssr: false,
});
const CategoryDescription = dynamic(() => import('@/src/components/Algolia/CategoryDescription/CategoryDescription'), {
  loading: () => <CategoryDescriptionSkeleton />,
  ssr: false,
});

const ShopByCategoryScroll = dynamic(() => import('../ShopByCategoryScroll/ShopByCategoryScroll'), {
  loading: () => <ShopByCategoryScrollSkeleton />,
  ssr: false,
});

const ShopByCategoryScrollRect = dynamic(() => import('../ShopByCategoryRect/ShopByCategoryRect'), {
  loading: () => <ShopByCategorySkeleton />,
  ssr: false,
});

const CategoryBannersFloor = dynamic(() => import('../CategoryBanners/CategoryBanners'), {
  loading: () => <MainBannerSkeletonV2 />,
  ssr: false,
});

export {
  MainBanner,
  MainBannerV2,
  getCallMainbannerV2,
  getCallMainbannerV1,
  MiniBanner,
  FourBanner,
  MultipleBigBanner,
  DualBannerCard,
  BlogBanner,
  HeroBanner,
  HeroBannerV2,
  PromiseFloor,
  PromiseFloorV2,
  HeadingMainTitle,
  ProductScroll,
  ProductCollage,
  ProductCarouselTitle,
  DyProductScroll,
  ProductCarouselTitlePerso,
  CategoryListing,
  CategoryCarousel,
  Persoreco,
  AppLink,
  ShopByCategory,
  ShopByCategoryScroll,
  ProductRecall,
  CategoryDescription,
  ShopByCategoryScrollRect,
  CategoryBannersFloor,
};
