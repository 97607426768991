import React from 'react';

function CategoryDescriptionSkeleton() {
  return (
    <div className="container mx-auto mt-4 px-4">
      <div className="skeleton dark w-1/4 h-12 mb-2 rounded-lg" />

      <div className="flex flex-col  mb-3 px-2 w-full space-y-2">
        <div className="skeleton dark w-full h-10 rounded-lg" />
        <div className="skeleton dark w-full  h-10 rounded-lg" />
      </div>
    </div>
  );
}

export default CategoryDescriptionSkeleton;
