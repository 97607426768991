/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { TabletDesktop, OnlyMobile } from '../../common/CustomMediaQuery';

const CategoryCarouselSkeleton = () => {
  return (
    <div>
      <TabletDesktop>
        <div className="p-3.5 flex items-center justify-center rounded bg-grey-50">
          <div className="skeleton dark mr-10 w-[120px] h-[21px]" />
          <div className="skeleton dark mr-10 w-[120px] h-[21px]" />
          <div className="skeleton dark mr-10 w-[120px] h-[21px]" />
          <div className="skeleton dark mr-10 w-[120px] h-[21px]" />
        </div>
      </TabletDesktop>
      <OnlyMobile>
        <div className="flex flex-nowrap overflow-y-auto">
          <div className="w-4/12 border-r border-solid border-grey-10">
            <div className="skeleton h-[40px]" />
          </div>
          <div className="w-4/12 border-r border-solid border-grey-10">
            <div className="skeleton h-[40px]" />
          </div>
          <div className="w-4/12 border-r border-solid border-grey-10">
            <div className="skeleton h-[40px]" />
          </div>
        </div>
      </OnlyMobile>
    </div>
  );
};

export default CategoryCarouselSkeleton;
