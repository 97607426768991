import React from 'react';
import ProductCardSkeleton from '../../common/ProductCards/ProductCardSkeleton';

const ProductCarouselSkeleton = () => {
  return (
    <div className="container mx-auto">
      <div className="px-4 lg:px-0 pb-2 md:pb-12 lg:pb-1">
        <div className="relative lg:flex lg:items-center">
          <div className="lg:w-1/6">
            <h2 className="relative z-10">
              <p className="text-18 lg:text-28 font-semibold text-grey-700 uppercase">
                <span className="skeleton dark">
                  <span className="invisible">CYCLING</span>
                </span>
              </p>
              <p className="-mt-2 lg:mt-3 text-28 lg:text-36 font-semibold text-grey-700 uppercase">
                <span className="skeleton dark">
                  <span className="invisible">PRODUCTS</span>
                </span>
              </p>
            </h2>
          </div>
          <div className="lg:w-5/6 -mx-2 mt-4 flex">
            <div className="w-1/2 md:w-1/3 lg:w-1/4 px-2">
              <ProductCardSkeleton />
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 px-2">
              <ProductCardSkeleton />
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 px-2 hidden md:block">
              <ProductCardSkeleton />
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 px-2 hidden lg:block">
              <ProductCardSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarouselSkeleton;
