import React from 'react';
import { Mobile } from '../../common/CustomMediaQuery';

const AppLinkSkeleton = () => {
  return (
    <Mobile>
      <div className="pb-10 px-3.5">
        <div className="skeleton p-4 flex items-center justify-center bg-grey-100">
          <div className="skeleton dark h-12 w-[250px]" />
          <div className="pl-3 flex flex-col items-center justify-center">
            <div className="skeleton dark m-1 h-[15px] w-[50px]" />
            <div className="skeleton dark m-2 h-[15px] w-[50px]" />
          </div>
        </div>
      </div>
    </Mobile>
  );
};
export default AppLinkSkeleton;
