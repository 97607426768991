import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const MultipleBannerSkeleton = () => {
  const bigDivStyle = { aspectRatio: '893 / 720' };
  const smallDivStyle = { aspectRatio: '59 / 15' };

  return (
    <div>
      <div className="container mx-auto px-2">
        <Desktop>
          <div className="flex">
            <div className="w-1/2 px-2">
              <div className="rounded-md skeleton dark" style={bigDivStyle} />
            </div>
            <div className="flex flex-col justify-between w-1/2 px-2">
              <div className="rounded-md skeleton dark" style={smallDivStyle} />
              <div className="rounded-md skeleton dark" style={smallDivStyle} />
              <div className="rounded-md skeleton dark" style={smallDivStyle} />
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="px-4">
            <div className="w-full my-1 rounded-md skeleton dark" style={bigDivStyle} />
            <div className="w-full my-1 rounded-md skeleton dark" style={smallDivStyle} />
            <div className="w-full my-1 rounded-md skeleton dark" style={smallDivStyle} />
            <div className="w-full my-1 rounded-md skeleton dark" style={smallDivStyle} />
          </div>
        </Mobile>
      </div>
    </div>
  );
};

export default MultipleBannerSkeleton;
