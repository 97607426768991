import React from 'react';

const ShopByCategoryScrollSkeleton = () => {
  return (
    <div className="w-full bg-white h-auto my-2 md:my-3 px-3 md:px-5 py-3 md:py-5">
      <h2 className="md:text-18 text-16 text-black font-bold tracking-normal" />
      <div className="py-2 md:py-3 content-center md:px-7">
        <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
          <div className="swiper-wrapper" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
            {Array.from(Array(4).keys()).map(() => (
              <a href="#" className="swiper-slide swiper-slide-next pointer-events-none" style={{ width: 78 }}>
                <div className="flex flex-col justify-start items-center">
                  <div className="relative">
                    <picture
                      className="relative overflow-hidden block "
                      aria-hidden="true"
                      style={{ paddingTop: '10%' }}
                    >
                      <div className="skeleton">
                        <div style={{ width: '64px', height: '68px' }}></div>
                      </div>
                    </picture>
                  </div>
                  <div className="text-12 text-center font-medium mt-1.5 max-w-[11ch] text-black break-words">
                    <span className="skeleton">
                      <span className="invisible">Winter Clothing</span>
                    </span>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
        </div>
      </div>
    </div>
  );
};

export default ShopByCategoryScrollSkeleton;
