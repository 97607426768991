import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const HeroBannerSkeleton = () => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="font-semibold xl:text-32 text-grey-700">
        <span className="skeleton dark">
          <span className="invisible">HERO BANNER</span>
        </span>
      </h2>
      <div className="mt-3 relative">
        <div>
          <span>
            <span
              style={{
                boxSizing: 'border-box',
                display: 'block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
              }}
            >
              <Mobile>
                <div className="skeleton dark" style={{ aspectRatio: '343 / 276' }} />
              </Mobile>
              <Desktop>
                <div className="skeleton dark" style={{ aspectRatio: '32 / 11' }} />
              </Desktop>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeroBannerSkeleton;
