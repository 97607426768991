/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const PromiseSekelton = () => {
  const dynamicHeight = '6';

  const divstyle = {
    height: `calc(244vw / ${dynamicHeight})`,
    width: `calc(164vw / ${dynamicHeight})`,
    // marginRight: 10
  };
  return (
    <div className="promise-skeleton">
      <Container className="mobile-sekelton pb-5">
        <Desktop>
          <div
            className="mb-4 align-items-center p-4 justify-content-between"
            style={{
              background: '#f4f4f4',
              borderRadius: '4px',
              display: 'flex',
            }}
          >
            <div
              className="skeleton dark mr-5"
              style={{
                width: '200px',
                height: '21px',
              }}
            />
            <div
              className="skeleton dark"
              style={{
                width: '200px',
                height: '21px',
              }}
            />
            <div
              className="skeleton dark"
              style={{
                width: '230px',
                height: '21px',
              }}
            />
            <div
              className="skeleton dark"
              style={{
                width: '180px',
                height: '21px',
              }}
            />
          </div>
        </Desktop>
        <Mobile>
          <Row>
            <Col md="3" style={{ paddingLeft: 15, flex: 'inherit' }}>
              <div className="skeleton" style={divstyle} />
            </Col>
            <Col md="3" style={{ paddingLeft: 5, flex: 'inherit' }}>
              <div className="skeleton" style={divstyle} />
            </Col>
            <Col md="3" style={{ paddingLeft: 5, flex: 'inherit' }}>
              <div className="skeleton" style={divstyle} />
            </Col>
            <Col md="3" style={{ paddingLeft: 5, flex: 'inherit' }}>
              <div className="skeleton" style={divstyle} />
            </Col>
          </Row>
        </Mobile>
      </Container>
    </div>
  );
};

export default PromiseSekelton;
