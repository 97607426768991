/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

const PromiseSekeltonV2 = () => {
  return (
    <div className="flex w-full p-5 m-0 rounded bg-grey-100">
      <div className="w-full md:w-4/12 lg:w-3/12">
        <div className="w-11/12 h-10 rounded skeleton dark" />
      </div>

      <div className="w-full md:w-4/12 lg:w-3/12">
        <div className="w-11/12 h-10 rounded skeleton dark" />
      </div>

      <div className="w-full md:w-4/12 lg:w-3/12">
        <div className="w-11/12 h-10 rounded skeleton dark" />
      </div>

      <div className="w-full md:w-4/12 lg:w-3/12">
        <div className="w-11/12 h-10 rounded skeleton dark" />
      </div>
    </div>
  );
};

export default PromiseSekeltonV2;
