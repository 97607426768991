import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const FourBannerSkeleton = () => {
  return (
    <div className="container pb-10 mx-auto">
      <div className="flex flex-wrap justify-center">
        {[1, 2, 3, 4].map((i) => (
          <React.Fragment key={i}>
            <Mobile>
              <div className="min-w-[150px] p-2 w-1/2 rounded-xl">
                <div className="skeleton dark h-[200px] w-full rounded-lg" />
              </div>
            </Mobile>
            <Desktop>
              <div className="py-2 px-2.5 w-3/12 rounded-xl">
                <div className="skeleton dark h-[300px] w-full rounded-lg" />
              </div>
            </Desktop>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FourBannerSkeleton;
