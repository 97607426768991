/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { withDeviceContext } from '@/context/DeviceContext';

const CategoryCarouselSkeleton = ({ contextDevice }) => {
  return (
    <div className="mb-3.5 py-5 w-full flex flex-wrap rounded bg-grey-100">
      <div className="w-full h-10 p-1 mb-1 skeleton dark sm:w-6/12 lg:w-1/5 lg:mx-4" />
      <div className="w-full h-10 p-1 mb-1 skeleton dark sm:w-6/12 lg:w-1/5 lg:mx-4" />
      <div className="w-full h-10 p-1 mb-1 skeleton dark sm:w-6/12 lg:w-1/5 lg:mx-4" />
      <div className="w-full h-10 p-1 mb-1 skeleton dark sm:w-6/12 lg:w-1/5 lg:mx-4" />
    </div>
  );
};

export default withDeviceContext(CategoryCarouselSkeleton);
