/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import dynamic from 'next/dynamic';
import DecaButton from '@/src/components/DecathlonTheme/DecaButton';
import { TabletMobile, Desktop } from '../../common/CustomMediaQuery';
const BannerStyles = dynamic(() => import('./Mainbanner.module.scss'), {
  ssr: false,
});
import DecaLink from '../../DecathlonTheme/DecaLink';
import Image from 'next/image';

const BannerV1 = ({ banner, i, GAEvent_mainBannerClick, maindata }) => {
  return (
    <div className={BannerStyles.mainBanner} key={i}>
      <DecaLink
        href={banner.url}
        onClick={() => GAEvent_mainBannerClick(maindata)}
        target={banner.openInNewTab ? '_blank' : ''}
        pageType={banner.pageType}
      >
        <Desktop>
          <Image
            data-test-id="home-page:animation-banner"
            src={banner.tabletImage}
            className="w-full"
            alt="BannerV1"
            width={1920}
            height={470}
          />
        </Desktop>
        <TabletMobile>
          <Image
            data-test-id="home-page:animation-banner"
            src={banner.mobileImage}
            className="w-full"
            alt="BannerV1"
            width={768}
            height={502}
            loading="eager"
          />
        </TabletMobile>
        {banner.ctaText && (
          <div className={BannerStyles.mainBannerCaption}>
            <h2 className={BannerStyles.bigTitle}>{banner.bigTitle}</h2>
            <h2 className={BannerStyles.smallTitle}>{banner.smallTitle}</h2>
            <p>{banner.text}</p>
            <DecaButton
              color="brand-blue"
              className="max-w-[200px] text-12 md:text-16 mt-4  caret-transparent  bg-blue-400 py-2 px-4 text-white"
            >
              {banner.ctaText}
            </DecaButton>
          </div>
        )}
      </DecaLink>
    </div>
  );
};
export default BannerV1;
