import React from 'react';

const MainBannerSkeleton = () => {
  return (
    <div className="main-banner-skeleton pb-5">
      <div className="skeleton dark hidden lg:block" style={{ aspectRatio: '1903 / 465' }} />
      <div className="skeleton dark hidden md:block lg:hidden" style={{ aspectRatio: '768 / 508' }} />
      <div className="skeleton dark md:hidden" style={{ aspectRatio: '375 / 271' }} />
    </div>
  );
};

export default MainBannerSkeleton;
