import React from 'react';

const MainBannerSkeletonV2 = () => {
  return (
    <div className="main-banner-skeleton pb-5">
      <div className="skeleton dark hidden lg:block" style={{ aspectRatio: '1903 / 554' }} />
      <div className="skeleton dark hidden md:block lg:hidden" style={{ aspectRatio: '768 / 379' }} />
      <div className="skeleton dark md:hidden" style={{ aspectRatio: '375 / 527' }} />
    </div>
  );
};

export default MainBannerSkeletonV2;
