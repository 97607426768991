import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const MiniBannerSkeleton = () => {
  const dynamicHw = '4.3';
  const divStyle = {
    height: `calc(95.7vw / ${dynamicHw})`,
    width: `calc(95.7vw / ${dynamicHw})`,
  };

  return (
    <>
      <Desktop>
        <div className="container pb-10 mx-auto">
          <div className="flex flex-wrap">
            <div className="px-2.5 w-3/12">
              <div className="rounded-lg skeleton dark" style={divStyle} />
            </div>
            <div className="px-2.5 w-3/12">
              <div className="rounded-lg skeleton dark" style={divStyle} />
            </div>
            <div className="px-2.5 w-3/12">
              <div className="rounded-lg skeleton dark" style={divStyle} />
            </div>
            <div className="px-2.5 w-3/12">
              <div className="rounded-lg skeleton dark" style={divStyle} />
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="container pb-8">
          <div className="flex overflow-y-auto flex-nowrap">
            {[1, 2, 3].map((t, i) => (
              <div className="px-2.5 w-[300px] md:w-1/3 h-[275px]" key={i}>
                <div className=" rounded-lg  w-[250px] sm:w-full skeleton dark h-3/4 sm:h-full" />
              </div>
            ))}
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default MiniBannerSkeleton;
