import React from 'react';
import classnames from 'classnames';
import DecaLink from '../../DecathlonTheme/DecaLink';
import { Desktop, TabletMobile } from '../../common/CustomMediaQuery';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Image from 'next/image';

const Banner = ({ banner, GAEvent_mainBannerClick, maindata, i }) => {
  if (!banner) return '';
  return (
    <div>
      <div className="h-full relative">
        <DecaLink
          href={banner.url}
          onClick={() => GAEvent_mainBannerClick(maindata)}
          pageType={banner.pageType}
          target={banner.openInNewTab ? '_blank' : ''}
        >
          <Desktop>
            <div>
              {banner.gradientOpacity && (
                <div
                  className={classnames('absolute z-10 inset-0 bg-black', {
                    'opacity-[0.1]': banner.gradientOpacity === 'light',
                    'opacity-[0.2]': banner.gradientOpacity === 'dark',
                    'opacity-[0.3]': banner.gradientOpacity === 'darker',
                  })}
                />
              )}
              <Image
                src={banner.desktopImage}
                height={616}
                width={2520}
                loading="eager"
                style={{
                  width: '100%',
                }}
              />
            </div>
          </Desktop>
          <TabletMobile>
            <div>
              {banner.gradientOpacity && (
                <div
                  className={classnames('absolute z-10 inset-0 bg-black', {
                    'opacity-[0.1]': banner.gradientOpacity === 'light',
                    'opacity-[0.2]': banner.gradientOpacity === 'dark',
                    'opacity-[0.3]': banner.gradientOpacity === 'darker',
                  })}
                />
              )}
              <Image src={banner.mobileImage} alt="Banner" height={529} width={661} loading="eager" />
            </div>
          </TabletMobile>
          <div className="w-full h-full absolute z-20 top-0 left-0">
            <div className="container flex items-end justify-between h-full mx-auto lg:items-center">
              <div className="w-full pb-12 md:w-1/2 lg:w-full">
                <div className="lg:px-24 lg:flex lg:justify-between">
                  <div>
                    {banner.bigTitle && (
                      <p className="text-shadow1 italic font-semibold text-center text-white text-40 md:text-42">
                        {banner.bigTitle}
                      </p>
                    )}
                    {banner.smallTitle && (
                      <p className="text-shadow1 italic text-center text-white text-28 md:text-36">
                        {banner.smallTitle}
                      </p>
                    )}
                    {banner.ctaText && (
                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="px-6 py-2 mt-4 font-semibold text-white bg-blue-400 rounded-3xl button text-12 md:text-22"
                        >
                          {banner.ctaText}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="absolute top-0 right-0 mt-6 mr-6 lg:static lg:mt-0 lg:mr-0">
                    {banner.priceText && (
                      <p className="text-shadow1 font-light text-right text-white text-16 md:text-22">
                        {banner.priceText}
                      </p>
                    )}
                    {banner.price && (
                      <p className=" px-2 font-bold text-center text-black bg-yellow-400 text-24 sm:28 md:text-36 lg:40">
                        <span className=" font-thin text-16 md:text-26  mr-1">&#8377;</span>
                        {banner.price}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DecaLink>
      </div>
    </div>
  );
};

export default Banner;
