import React, { useContext } from 'react';

import constants from '@/src/constants';
import { deviceTypes, DeviceContext } from '@/context/DeviceContext';

const shopByCategorySkeleton = () => {
  const device = useContext(DeviceContext);
  const aspectRatio =
    device === deviceTypes.mobile
      ? constants.decaImage.shopByCategory.mobile
      : constants.decaImage.shopByCategory.desktop;

  return (
    <div>
      <div className="w-full bg-white h-auto my-2 md:my-3 px-3 md:px-5 py-3 md:py-5">
        <h2 className="md:text-18 text-16 text-black font-bold tracking-normal">
          <span className="skeleton">
            <span className="invisible inline-block w-80">&nbsp;</span>
          </span>
        </h2>
        <div className="py-2 flex flex-wrap md:px-7 md:py-3 md:flex-nowrap md:justify-around">
          {Array.from(Array(8).keys()).map((key) => (
            <a href="jackets-under-999-21505" className="w-1/4 max-w-36" key={key}>
              <div className="flex flex-col gap-1 justify-start items-center">
                <div className="w-full px-2.5 relative">
                  <picture
                    className="relative overflow-hidden block"
                    aria-hidden="true"
                    style={{ paddingTop: `${aspectRatio}%` }}
                  >
                    <div className="skeleton img-fluid bg-no-repeat bg-[length:60px] bg-center absolute inset-0 block min-w-full max-w-full min-h-full max-h-full w-16 bg-none" />
                  </picture>
                </div>
                <div className="skeleton md:text-16 text-10 text-center font-medium md:max-w-[22ch] max-w-[16ch] text-black break-words">
                  <span className="invisible inline-block w-14">&nbsp;</span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default shopByCategorySkeleton;
