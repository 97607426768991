import React from 'react';

const DualBannerSkeleton = () => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="font-semibold xl:text-32 text-grey-700">
        <span className="skeleton dark m-4">
          <span className="invisible">Dual Banner</span>
        </span>
      </h2>

      <div className="flex flex-col md:flex-row mb-3 px-2 w-full space-y-2 md:space-y-0 md:space-x-4">
        <div className="skeleton w-full md:w-1/2 dark h-60 rounded-lg" />
        <div className="skeleton w-full md:w-1/2 dark h-60 rounded-lg" />
      </div>
    </div>
  );
};

export default DualBannerSkeleton;
