import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const HeroBannerSkeletonV2 = () => {
  return (
    <div className="container mx-auto rounded-lg overflow-hidden">
      <Desktop>
        <div className="skeleton dark" style={{ aspectRatio: '384 / 77' }} />
      </Desktop>
      <Mobile>
        <div className="skeleton dark" style={{ aspectRatio: '343 / 142' }} />
      </Mobile>
    </div>
  );
};

export default HeroBannerSkeletonV2;
