import React from 'react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';
import ProductCardSkeleton from '@/src/components/common/ProductCards/ProductCardSkeleton';

const ProductCollageSkeleton = () => {
  return (
    <>
      <Desktop>
        <div className="container mx-auto -mx-auto">
          <div className="flex">
            <div className="w-2/5 px-1">
              <div>
                <div className="skeleton w-full" style={{ aspectRatio: '375 / 182' }} />
              </div>
              <div className="flex mt-4">
                <div className="w-1/2 px-1">
                  <ProductCardSkeleton />
                </div>
                <div className="w-1/2 px-1">
                  <ProductCardSkeleton />
                </div>
              </div>
            </div>
            <div className="w-2/5 px-1">
              <div className="flex">
                <div className="w-1/2 px-1">
                  <ProductCardSkeleton />
                </div>
                <div className="w-1/2 px-1">
                  <ProductCardSkeleton />
                </div>
              </div>
              <div className="mt-2">
                <div className="flex">
                  <div className="w-1/2">
                    <div className="skeleton w-full" style={{ aspectRatio: '1 / 1' }} />
                  </div>
                  <div className="w-1/2 px-4 pt-8 border border-grey-100">
                    <div className="skeleton dark h-[43px] w-[84px]" />
                    <div className="skeleton dark mt-3 h-[21px] w-10/12 md:w-40 lg:w-[200px]" />
                    <div className="skeleton dark mt-3 mr-4 h-[21px] w-[60px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/5 px-1">
              <div>
                <ProductCardSkeleton />
              </div>
              <div className="mt-2">
                <div className="skeleton w-full" style={{ aspectRatio: '1 / 1' }} />
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="w-full overflow-x-auto">
          <div className="flex">
            <div className="w-1/2 sm:w-1/3 mr-1 sm:mr-[20px] pr-1 flex-shrink-0 sm:flex-shrink">
              <div className="skeleton w-full" style={{ aspectRatio: '73 / 142' }} />
            </div>
            <div className="w-1/2 sm:w-1/3 mr-1 sm:mr-[20px] pr-1 sm:pr-px">
              <ProductCardSkeleton />
            </div>
            <div className="w-1/3 hidden sm:block pr-1 mr-1">
              <ProductCardSkeleton />
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default ProductCollageSkeleton;
